<template>
  <v-avatar color="grey" :size="size">
    <template v-if="avatar">
      <v-img :src="avatar"/>
    </template>
    <template v-else-if="initials">
      <span class="white--text">
        {{ initials }}
      </span>
    </template>
    <template v-else>
      <v-icon>mdi-account-outline</v-icon>
    </template>
  </v-avatar>
</template>

<script>
export default {
  props: {
    size: {
      default: '38'
    },
    avatar: {
      default: null,
    },
    initials: {
      default: null,
    }
  },
}
</script>
