import http from './http'

const students = () => {
  return http.get('user/students')
}

const therapists = () => {
  return http.get('user/therapists')
}

const coordinators = () => {
  return http.get('user/coordinators')
}

const collaborators = () => {
  return http.get('user/collaborators')
}

const companions = () => {
  return http.get('user/companions')
}

const student = (id) => {
  return http.get('user/student', {
    params: {id}
  })
}

export default { students, therapists, coordinators, collaborators, companions, student }