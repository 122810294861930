<template>
  <v-loading v-model="loading">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Estudantes
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-container fluid class="px-4 py-0">
      <v-row>
        <template v-for="student in students">
          <v-col :key="student.id" cols="6" sm="6" md="4" lg="3">
            <v-card outlined @click="goto(student)">
              <v-card-text class="text-center pb-0">
                <Avatar size="60" :avatar="student.avatar.path" :initials="student.initials" />
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ student.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-loading>
</template>

<script>
  import Avatar from '../../components/Avatar'
  import UserService from '../../services/user'

  export default {
    components: {
      Avatar,
    },
    data: () => ({
      loading: true,
      students: [],
    }),
    mounted() {
      UserService.students()
        .then(data => {
          this.students = data
        })
        .then(() => this.loading = false)
    },
    methods: {
      goto(student) {
        this.$router.push({
          name: 'student', 
          query: {
            id: student.id
          }
        })
      }
    }
  }
</script>
